<template>
  <section>
    <div class="form-group form-group-sm">
      <label for="url">{{ $t("url") }}</label>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          :placeholder="$t('url')"
          ref="inp"
          v-model="src"
        />
        <div class="input-group-addon btn" @click.stop.prevent="reset">
          <i class="fa fa-close"></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "DetailFormCustomPanel",
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    },
    src: {
      set(value) {
        if (this?.panel?.options) {
          let panel = this.panel;
          panel.options.src = value;
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        return this?.panel?.options?.src || "";
      }
    }
  },
  methods: {
    reset() {
      this.src = "";
      this.$nextTick(() => {
        this.$refs.inp.focus();
      });
    }
  }
};
</script>

<style scoped></style>
