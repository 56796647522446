<template>
  <div class="modal" ref="publishForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span class="screen-name">
              <i class="fa fa-briefcase"></i>
              <span>
                {{ $tc("clone") }} - {{ $tc("screen") }} - [#{{ screen.id }}]
              </span>
            </span>
          </h4>
        </div>
        <div class="modal-body">
          <form action="" role="form">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{ $t("name") }}</label>
                <input
                  type="text"
                  data-testid="name"
                  class="form-control"
                  v-model="form.name"
                  ref="name"
                  placeholder="name"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{ $t("description") }}</label>
                <textarea
                  rows="2"
                  data-testid="description"
                  cols="80"
                  type="text"
                  class="form-control"
                  placeholder="description"
                  v-model="form.description"
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-right">
          <div class="pull-left" style="color:#808080">
            {{ $t("last_version") }}: {{ screen.name }}/{{
              screen.revision_code || 0.0
            }}
          </div>
          <button
            class="btn btn-default"
            data-testid="close"
            v-on:click.stop="onCancel"
          >
            {{ $t("cancel") }}
          </button>
          <button
            class="btn btn-primary"
            data-testid="save"
            v-on:click.stop="onConfirm"
            v-bind:disabled="!isValid || busy"
          >
            {{ $t("confirm") }}
          </button>
        </div>
        <div class="overlay overlay-local" v-if="busy">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenService from "@/services/screen";
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "SaveAsForm",
  mixins: [MixinAlert],
  props: {
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    }
  },
  data() {
    return {
      busy: false,
      form: {
        name: "",
        description: "",
        public: false
      },
      screen_id: ""
    };
  },
  computed: {
    template() {
      let draft = this.$store.getters["dashboard/draft"] || {};
      if (draft?.screenId == this.screenId) {
        return draft.template;
      }
      return null;
    },
    isValid() {
      return this.screen &&
        this.template &&
        this.form.name &&
        this.form.name != this.screen.name
        ? true
        : false;
    },
    screen() {
      return this.$store.getters["dashboard/screen"](this.screenId);
    },
    contractId() {
      return this.$store.getters["user/contract"].id;
    }
  },
  methods: {
    payload() {
      if (!this.isValid) return null;
      //===============
      let screen = JSON.parse(JSON.stringify(this.screen));
      let payload = JSON.parse(JSON.stringify(this.form));
      let template = JSON.parse(JSON.stringify(this.template));
      payload.public = false;
      payload.contract_id = this.contractId;
      if (screen.reference_connectors?.length)
        payload.reference_connectors_ids = screen.reference_connectors.map(
          ({ id }) => id
        );
      else if (template.ref_map)
        payload.reference_connectors_ids = Object.keys(template.ref_map).map(
          (key) => template.ref_map[key]
        );
      delete template.ref_map;
      delete template.draft;
      payload.content = template;
      return payload;
    },
    onCancel() {
      this.close();
    },
    onConfirm() {
      let self = this;
      let payload = this.payload();
      if (!payload) return;
      self.busy = true;
      this.service.save(payload).then((ret) => {
        self.busy = false;
        if (self.validateSaveResponse(ret)) {
          self.onSuccess(ret);
        } else {
          self.onError();
        }
      });
    },
    onSuccess(ret) {
      this.screen_id = ret.id;
      this.$store.commit("dashboard/SET_SCREEN", ret);
      this.$store.dispatch("dashboard/initDraft", ret.id);
      let draft = this.$store.getters["dashboard/draft"];
      if (draft && draft.template) {
        // update new name
        let tpl = JSON.parse(JSON.stringify(draft.template));
        tpl.title = ret.name;
        this.$store.commit("dashboard/SAVE_DRAFT", {
          screenId: ret.id,
          template: tpl
        });
      }
      this.showAlert(() => {
        this.close();
      });
    },
    onError() {
      this.showAlert();
    },
    close() {
      $(this.$refs.publishForm).modal("hide");
    },
    open() {
      $(this.$refs.publishForm).modal("show");
    }
  },
  mounted() {
    let self = this;
    this.screen_id = this.screen.id;
    this.form.name = this.screen.name;
    this.form.description = this.screen.description;
    $(this.$refs.publishForm)
      .on("shown.bs.modal", () => {
        self.$root.$emit("editor.keyboard:stop");
        self.$nextTick(() => {
          self.$refs.name.focus();
        });
        self.$emit("show");
      })
      .on("hidden.bs.modal", () => {
        self.$root.$emit("editor.keyboard:start");
        self.$emit("close", self.screen_id);
      });
    self.open();
  },
  created() {
    this.service = new ScreenService();
  }
};
</script>

<style scoped>
.overlay-local {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2em;
  text-align: center;
  color: #607d8b;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
}

.overlay-local .fa-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
}

span.screen-name i {
  color: gray;
}
span.screen-name span {
  margin-left: 3px;
}
</style>
