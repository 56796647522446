<template>
  <ul class="nav navbar-nav hidden-xs" v-if="canUndo || canRedo">
    <li class="">
      <span
        class="btn btn-img btn-undo"
        :class="{ disabled: !canUndo }"
        @click.stop.prevent="$root.$emit('undo')"
        :disabled="!canUndo"
        :title="$t('undo')"
      ></span>
    </li>
    <li class="">
      <span
        class="btn btn-img btn-redo"
        :disabled="!canRedo"
        :class="{ disabled: !canRedo }"
        @click.stop.prevent="$root.$emit('redo')"
        :title="$t('redo')"
      ></span>
    </li>
  </ul>
</template>

<script>
import { debounce, isEqual } from "lodash";

// In order to avoid memory issues, it tracks last 10 layout changes (maz)
const STACK_SIZE = 10;

export default {
  name: "DashboardChangesTracker",
  data() {
    return {
      canUndo: false,
      canRedo: false
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"];
    },
    display() {
      return this.draft && this.draft.template || null;
    }
  },
  watch: {
    display: {
      handler(n, o) {
        if (isEqual(n, o)) return;
        if (this.skip) {
          this.skip = false;
          return;
        } else if (n && o && n.layout && o.layout && !isEqual(n.layout, o.layout)) {
          this._save(n);
        }
      },
      deep: true
    }
  },
  methods: {
    save(value) {
      let o = this.undoList.length && (this.undoList[this.undoList.length - 1]) || "";
      let n = value;
      if (!isEqual(n, o)) {
        if (this.undoList.length >= STACK_SIZE) {
          this.initial = this.undoList.shift();
        };
        this.undoList.push(JSON.parse(JSON.stringify(n)));
        this.validate();
        this.$root.$emit("editor.keyboard:focus");
      }
    },
    validate() {
      this.canUndo = this.undoList.length ? true : false;
      this.canRedo = this.redoList.length ? true : false;
    },
    undo() {
      if (!this.canUndo) return;
      if (this.undoList.length) {
        this.redoList.push(this.undoList.pop());
        this.$store.dispatch("dashboard/saveDraft", {
          screenId: this.draft.screenId,
          template: this.undoList.length ? this.undoList[this.undoList.length - 1] : this.initial
        });
        this.skip = true;
        this.$nextTick(this._notifyWindowResize);
      }
      this.validate();
    },
    redo() {
      if (!this.canRedo) return;
      if (this.redoList.length) {
        this.$store.dispatch("dashboard/saveDraft", {
          screenId: this.draft.screenId,
          template: this.redoList.length ? this.redoList[this.redoList.length - 1] : this.initial
        });
        this.undoList.push(this.redoList.pop());
        this.skip = true;
        this.$nextTick(this._notifyWindowResize);
      }
      this.validate();
    }
  },
  beforeCreate() {
    // non reactive properties
    this.skip = false;
    this.initial = null;
    this.undoList = [];
    this.redoList = [];
    this._save = debounce((payload) => {
      this.save(payload);
    }, 500);
    this._notifyWindowResize = debounce(() => {
      this.$root.$emit("panel:resized");
    }, 500);
  },
  created() {
    this.$root.$on("undo", this.undo);
    this.$root.$on("redo", this.redo);
    this.initial = JSON.parse(JSON.stringify(this.display));
  },
  beforeDestroy() {
    this.$root.$off("undo", this.undo);
    this.$root.$off("redo", this.redo);
    this.skip = false;
    this.initial = null;
    this.undoList = [];
    this.redoList = [];
  }
}
</script>

<style scoped>
ul.navbar-nav > li {
  margin-left: 10px;
}

.btn:hover {
  opacity: 0.6;
}

.btn.disabled {
  color: #7f7f7f;
  opacity: 0.5;
}

.btn-img {
  width: 20px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50%;
}
.btn-undo,
.btn-undo:active {
  background-image: url(/static/common/images/undo.png);
}

.btn-redo,
.btn-redo:active {
  background-image: url(/static/common/images/redo.png);
}
</style>