<template>
  <DetailForm persistent="toogle_history_panel">
    <DetailFormHistory />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormHistory from "@/components/control-sidebar/property-editors/detail-form-history.vue";

export default {
  name: "HistoryForm",
  components: {
    DetailForm,
    DetailFormHistory
  }
};
</script>
